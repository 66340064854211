.counterUnit {
  display: inline-block;
  line-height: 0.7em;
  text-align: center;
  .default {
    font-family: 'Bank Gothic', 'futura', 'arial', 'sans-serif';
    width: 2em;
    display: inline-block;
    background: none;
    margin: 0.25em;
  }
  .squares {
    font-family: 'Courier', 'Andale Mono', 'monospace';
    display: inline-block;
    margin: 0.25em;
    padding: 0.5em;
  }
  .circles {
    font-family: 'Arial Black', 'Arial', 'sans-serif';
    width: 2.25em;
    height: 2.25em;
    display: inline-block;
    border-radius: 2em;
    margin: 0.25em;
    padding-top: 0.55em;
    line-height: 1em;
  }
}
