#featureBoxOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1100;
}

#fullOverlayContainer {
  position: fixed;
  top: 5%;
  right: 5%;
  left: 5%;
  bottom: 5%;
  background-color: white;
  text-align: center;
  color: #222;
  overflow: scroll;
  box-shadow: #000 4px 4px 20px 4px;
  border-radius: 10px;

  #closeButton {
    font-size: 40px;
    color: #999;
    position: fixed;
    top: 5%;
    right: 5%;
    margin-right: 15px;
  }

  #closeButton:hover {
    color: #444;
  }

  #content {
    padding: 10px;
  }

  #title {
    margin-top: 15px;
  }

  #featuredImage {
    max-height: 300px;
    max-width: 90%;
    margin-top: 50px;
    display: inline-block;
  }
}
