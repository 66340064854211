.cookie-prompt{
  display: block;
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
  background: #435270;
  z-index: 4000;
  border-radius: 10px;
  font-family: Helvetica;

  a, a:hover, a:active{
    color: #94c0f3;
  }

  .cookie-body{
    padding: 10px;
    text-align: center;

    .cookie-text{
      color: #FFF;
      width: 90%;
      display: inline-block;
      margin-right: 20px;
      vertical-align: center;
      line-height: normal;
      font-size: 16px;
    }

    .cookie-buttons{
      margin-top: 10px;
    }
  }
}

.cookie-prompt.at-top{
  top: 10px;
  bottom: auto;
}

@media(min-width: 700px) {
  .cookie-prompt{
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    .cookie-body{

    }
  }
}
