.blog-post-list {

  .post {
    margin-bottom: 20px !important;
    position: relative;
    border-radius: 8px;
    padding: 5px;
    background: rgba(228, 228, 228, 0.5);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

    h2{
      font-size: 2rem;
      small{
        font-size: 1.5rem;
      }
    }

    .post-title{

    }

    .featured-image-wrapper {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      margin-top: 20px;

      .featured-image-overlay {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 8px;
      }
    }

    .post-content {
      margin-bottom: 50px;
    }

    .post-read-more{
      position: absolute;
      bottom: 5px;
      right: 5px;
    }

    @media(max-width: 991px){
      .post-content{
        margin-bottom: 50px;
      }
      .post-read-more{
        width: 100%;
        text-align: center;
        right: auto;
      }
    }
  }
}

.blog-post{
  .blog-featured-image{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center 25%;
    border-radius: 8px;
    position: relative;
  }

  .blog-featured-image.no-image:before{
    width: 100%;
    height: 100%;
    content: 'Featured Image';
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 8px;
    text-align: center;
    padding-top: 10%;
    font-size: 35px;
    color: rgba(255,255,255,0.5);
  }

  .post-content-wrapper{
    margin-top: 30px;
    font-weight: normal;

    .post-content{
      margin-top: 20px;
      position: relative;
    }
  }
}
