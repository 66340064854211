.fr-no-borders {
  border: none;

  tr {
    border: none;

    td {
      border: none;
    }
  }
}

.fr-centered{
  margin: auto !important;
}

.fr-left{
  margin: 0 !important;
}
