#pixie-wrapper{
  position: fixed;
  z-index: 2000;
}
.no-scroll{
    overflow: hidden;
}

.fw-group > div, .fw-group > section{
    position: relative;
}

.fw-group{
    position: relative;
    .moving-section-overlay{
        display: none;
    }
}

.fw-group-hidden{
  display: none;
}

.rdtPicker{
  margin-top: -275px !important;
}

.date-picker-popover{
  color: #000;
}

.fw-group>div, .fw-group>section{
    overflow: hidden;
}

@media(min-width: 850px) {
    .overlap-group {
        position: absolute !important;
    }
}
@media(max-width: 849px){
    .overlap-group{
        position: relative !important;
        width: 100% !important;
        margin-left: 0 !important;;
        margin-top: 0 !important;
    }
}

.fw-unselectable {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.fw-unscrollable {
    overflow: hidden;
}

.fw-outline-dashed {
    outline: rgba(0, 0, 0, .5) dashed 2px;
}

div, span, iframe{
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

/**[data-fw-model="PageSection"]{
    display: flex !important;
}*/


.fw-noParallax{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.fw-parallax{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    z-index: 0;
}

img.fw-parallax{
    transform: translate3d(0px, 0px, 0px);
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;

    /* Set up proportionate scaling */
    width: 100%;
    height: auto;

    /* Set up positioning */
    position: absolute;
    top: 0;
    left: 0;
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
    img.fw-parallax{
        left: 50%;
        margin-left: -512px;   /* 50% */
    }
}

.fw-parallax video{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
}

.fw-noParallax video{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.fw-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

/*.pswp{
    display: none;
}*/

.aplayer .aplayer-pic{
    background-repeat: no-repeat;
    background-position: center center;
}

.vertically-aligned{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

/* FIXES AN ISSUE WITH GLYPHICONS */
.alert:before{
    content: '' !important;
}

.full-height{
    display: flex;
    align-items: center;

    .react-grid-layout{
        width: 100%;
    }
}

.fw-tab > div{
    height: 100%;
}

.tabContainer{
    width: 100%;
    max-width: 100%;
}

body{
    overflow-x: hidden;
    display: block;
}

html{
  overflow-x: visible !important;
}

#social-links{
    font-size: 24px;
}
.tileContainer{
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    font-size: 26px;
}
.calendarContainer > .container{
    width: 100% !important;
}

#adminEditLink {
    text-decoration: underline;
    padding-bottom: 15px;
}

#adminLoginLink {
    text-decoration: underline;
    padding-bottom: 15px;
}

.tileSection a:hover {
    text-decoration: none;
}

.btn-sm {
    padding: 2px 4px;
}

.btn-md {
    padding: 5px 10px;
}

.btn-lg {
    padding: 10px 20px;
}

.btn-xl {
    padding: 15px 30px;
}

.jumbotron p span {
    line-height: 1em;
}

@import "fw-menu-basics";
@import "fw-blog-basics";
@import "fw-media-section";
@import "fw-photo-gallery-section";
@import 'printDirectory';
@import "root";

html{
    font-size: 100%;
}

footer{
  display: none;
}

// hide the recaptcha badge
.grecaptcha-badge { visibility: hidden; }

.paragraph:before{
  content: "" !important;
}

table.table:before{
  content: '';
}

.font-1{
  font-size: 1px;
}
.font-2{
  font-size: 2px;
}
.font-3{
  font-size: 3px;
}
.font-4{
  font-size: 4px;
}
.font-5{
  font-size: 5px;
}
.font-6{
  font-size: 6px;
}
.font-7{
  font-size: 7px;
}
.font-8{
  font-size: 8px;
}
.font-9{
  font-size: 9px;
}
.font-10{
  font-size: 10px;
}
.font-11{
  font-size: 11px;
}
.font-12{
  font-size: 12px;
}
.font-13{
  font-size: 13px;
}
.font-14{
  font-size: 14px;
}
.font-15{
  font-size: 15px;
}
.font-16{
  font-size: 16px;
}
.font-17{
  font-size: 17px;
}
.font-18{
  font-size: 18px;
}
.font-19{
  font-size: 19px;
}
.font-20{
  font-size: 20px;
}
.font-21{
  font-size: 21px;
}
.font-22{
  font-size: 22px;
}
.font-23{
  font-size: 23px;
}
.font-24{
  font-size: 24px;
}
.font-25{
  font-size: 25px;
}
.font-26{
  font-size: 26px;
}
.font-27{
  font-size: 27px;
}
.font-28{
  font-size: 28px;
}
.font-29{
  font-size: 29px;
}
.font-30{
  font-size: 30px;
}
.font-31{
  font-size: 31px;
}
.font-32{
  font-size: 32px;
}
.font-33{
  font-size: 33px;
}
.font-34{
  font-size: 34px;
}
.font-35{
  font-size: 35px;
}
.font-36{
  font-size: 36px;
}
.font-37{
  font-size: 37px;
}
.font-38{
  font-size: 38px;
}
.font-39{
  font-size: 39px;
}
.font-40{
  font-size: 40px;
}
.font-41{
  font-size: 41px;
}
.font-42{
  font-size: 42px;
}
.font-43{
  font-size: 43px;
}
.font-44{
  font-size: 44px;
}
.font-45{
  font-size: 45px;
}
.font-46{
  font-size: 46px;
}
.font-47{
  font-size: 47px;
}
.font-48{
  font-size: 48px;
}
.font-49{
  font-size: 49px;
}
.font-50{
  font-size: 50px;
}
.font-51{
  font-size: 51px;
}
.font-52{
  font-size: 52px;
}
.font-53{
  font-size: 53px;
}
.font-54{
  font-size: 54px;
}
.font-55{
  font-size: 55px;
}
.font-56{
  font-size: 56px;
}
.font-57{
  font-size: 57px;
}
.font-58{
  font-size: 58px;
}
.font-59{
  font-size: 59px;
}
.font-60{
  font-size: 60px;
}
.font-61{
  font-size: 61px;
}
.font-62{
  font-size: 62px;
}
.font-63{
  font-size: 63px;
}
.font-64{
  font-size: 64px;
}
.font-65{
  font-size: 65px;
}
.font-66{
  font-size: 66px;
}
.font-67{
  font-size: 67px;
}
.font-68{
  font-size: 68px;
}
.font-69{
  font-size: 69px;
}
.font-70{
  font-size: 70px;
}
.font-71{
  font-size: 71px;
}
.font-72{
  font-size: 72px;
}
.font-73{
  font-size: 73px;
}
.font-74{
  font-size: 74px;
}
.font-75{
  font-size: 75px;
}
.font-76{
  font-size: 76px;
}
.font-77{
  font-size: 77px;
}
.font-78{
  font-size: 78px;
}
.font-79{
  font-size: 79px;
}
.font-80{
  font-size: 80px;
}
.font-81{
  font-size: 81px;
}

@media(max-width: 850px) {
  .font-1{
    font-size: 0.8px;
  }
  .font-2{
    font-size: 1.6px;
  }
  .font-3{
    font-size: 2.4px;
  }
  .font-4{
    font-size: 3.2px;
  }
  .font-5{
    font-size: 4px;
  }
  .font-6{
    font-size: 4.8px;
  }
  .font-7{
    font-size: 5.6px;
  }
  .font-8{
    font-size: 6.4px;
  }
  .font-9{
    font-size: 7.2px;
  }
  .font-10{
    font-size: 8px;
  }
  .font-11{
    font-size: 8.8px;
  }
  .font-12{
    font-size: 9.6px;
  }
  .font-13{
    font-size: 10.4px;
  }
  .font-14{
    font-size: 11.2px;
  }
  .font-15{
    font-size: 12px;
  }
  .font-16{
    font-size: 12.8px;
  }
  .font-17{
    font-size: 13.6px;
  }
  .font-18{
    font-size: 14.4px;
  }
  .font-19{
    font-size: 15.2px;
  }
  .font-20{
    font-size: 16px;
  }
  .font-21{
    font-size: 16.8px;
  }
  .font-22{
    font-size: 17.6px;
  }
  .font-23{
    font-size: 18.4px;
  }
  .font-24{
    font-size: 19.2px;
  }
  .font-25{
    font-size: 20px;
  }
  .font-26{
    font-size: 20.8px;
  }
  .font-27{
    font-size: 21.6px;
  }
  .font-28{
    font-size: 22.4px;
  }
  .font-29{
    font-size: 23.2px;
  }
  .font-30{
    font-size: 24px;
  }
  .font-31{
    font-size: 24.8px;
  }
  .font-32{
    font-size: 25.6px;
  }
  .font-33{
    font-size: 26.4px;
  }
  .font-34{
    font-size: 27.2px;
  }
  .font-35{
    font-size: 28px;
  }
  .font-36{
    font-size: 28.8px;
  }
  .font-37{
    font-size: 29.6px;
  }
  .font-38{
    font-size: 30.4px;
  }
  .font-39{
    font-size: 31.2px;
  }
  .font-40{
    font-size: 32px;
  }
  .font-41{
    font-size: 32.8px;
  }
  .font-42{
    font-size: 33.6px;
  }
  .font-43{
    font-size: 34.4px;
  }
  .font-44{
    font-size: 35.2px;
  }
  .font-45{
    font-size: 36px;
  }
  .font-46{
    font-size: 36.8px;
  }
  .font-47{
    font-size: 37.6px;
  }
  .font-48{
    font-size: 38.4px;
  }
  .font-49{
    font-size: 39.2px;
  }
  .font-50{
    font-size: 40px;
  }
  .font-51{
    font-size: 40.8px;
  }
  .font-52{
    font-size: 41.6px;
  }
  .font-53{
    font-size: 42.4px;
  }
  .font-54{
    font-size: 43.2px;
  }
  .font-55{
    font-size: 44px;
  }
  .font-56{
    font-size: 44.8px;
  }
  .font-57{
    font-size: 45.6px;
  }
  .font-58{
    font-size: 46.4px;
  }
  .font-59{
    font-size: 47.2px;
  }
  .font-60{
    font-size: 48px;
  }
  .font-61{
    font-size: 48.8px;
  }
  .font-62{
    font-size: 49.6px;
  }
  .font-63{
    font-size: 50.4px;
  }
  .font-64{
    font-size: 51.2px;
  }
  .font-65{
    font-size: 52px;
  }
  .font-66{
    font-size: 52.8px;
  }
  .font-67{
    font-size: 53.6px;
  }
  .font-68{
    font-size: 54.4px;
  }
  .font-69{
    font-size: 55.2px;
  }
  .font-70{
    font-size: 56px;
  }
  .font-71{
    font-size: 56.8px;
  }
  .font-72{
    font-size: 57.6px;
  }
  .font-73{
    font-size: 58.4px;
  }
  .font-74{
    font-size: 59.2px;
  }
  .font-75{
    font-size: 60px;
  }
  .font-76{
    font-size: 60.8px;
  }
  .font-77{
    font-size: 61.6px;
  }
  .font-78{
    font-size: 62.4px;
  }
  .font-79{
    font-size: 63.2px;
  }
  .font-80{
    font-size: 64px;
  }
  .font-81{
    font-size: 64.8px;
  }
}

@media(max-width: 600px) {
  .font-1{
    font-size: 0.6px;
  }
  .font-2{
    font-size: 1.2px;
  }
  .font-3{
    font-size: 1.8px;
  }
  .font-4{
    font-size: 2.4px;
  }
  .font-5{
    font-size: 3px;
  }
  .font-6{
    font-size: 3.6px;
  }
  .font-7{
    font-size: 4.2px;
  }
  .font-8{
    font-size: 4.8px;
  }
  .font-9{
    font-size: 5.4px;
  }
  .font-10{
    font-size: 6px;
  }
  .font-11{
    font-size: 6.6px;
  }
  .font-12{
    font-size: 7.2px;
  }
  .font-13{
    font-size: 7.8px;
  }
  .font-14{
    font-size: 8.4px;
  }
  .font-15{
    font-size: 9px;
  }
  .font-16{
    font-size: 9.6px;
  }
  .font-17{
    font-size: 10.2px;
  }
  .font-18{
    font-size: 10.8px;
  }
  .font-19{
    font-size: 11.4px;
  }
  .font-20{
    font-size: 12px;
  }
  .font-21{
    font-size: 12.6px;
  }
  .font-22{
    font-size: 13.2px;
  }
  .font-23{
    font-size: 13.8px;
  }
  .font-24{
    font-size: 14.4px;
  }
  .font-25{
    font-size: 15px;
  }
  .font-26{
    font-size: 15.6px;
  }
  .font-27{
    font-size: 16.2px;
  }
  .font-28{
    font-size: 16.8px;
  }
  .font-29{
    font-size: 17.4px;
  }
  .font-30{
    font-size: 18px;
  }
  .font-31{
    font-size: 18.6px;
  }
  .font-32{
    font-size: 19.2px;
  }
  .font-33{
    font-size: 19.8px;
  }
  .font-34{
    font-size: 20.4px;
  }
  .font-35{
    font-size: 21px;
  }
  .font-36{
    font-size: 21.6px;
  }
  .font-37{
    font-size: 22.2px;
  }
  .font-38{
    font-size: 22.8px;
  }
  .font-39{
    font-size: 23.4px;
  }
  .font-40{
    font-size: 24px;
  }
  .font-41{
    font-size: 24.6px;
  }
  .font-42{
    font-size: 25.2px;
  }
  .font-43{
    font-size: 25.8px;
  }
  .font-44{
    font-size: 26.4px;
  }
  .font-45{
    font-size: 27px;
  }
  .font-46{
    font-size: 27.6px;
  }
  .font-47{
    font-size: 28.2px;
  }
  .font-48{
    font-size: 28.8px;
  }
  .font-49{
    font-size: 29.4px;
  }
  .font-50{
    font-size: 30px;
  }
  .font-51{
    font-size: 30.6px;
  }
  .font-52{
    font-size: 31.2px;
  }
  .font-53{
    font-size: 31.8px;
  }
  .font-54{
    font-size: 32.4px;
  }
  .font-55{
    font-size: 33px;
  }
  .font-56{
    font-size: 33.6px;
  }
  .font-57{
    font-size: 34.2px;
  }
  .font-58{
    font-size: 34.8px;
  }
  .font-59{
    font-size: 35.4px;
  }
  .font-60{
    font-size: 36px;
  }
  .font-61{
    font-size: 36.6px;
  }
  .font-62{
    font-size: 37.2px;
  }
  .font-63{
    font-size: 37.8px;
  }
  .font-64{
    font-size: 38.4px;
  }
  .font-65{
    font-size: 39px;
  }
  .font-66{
    font-size: 39.6px;
  }
  .font-67{
    font-size: 40.2px;
  }
  .font-68{
    font-size: 40.8px;
  }
  .font-69{
    font-size: 41.4px;
  }
  .font-70{
    font-size: 42px;
  }
  .font-71{
    font-size: 42.6px;
  }
  .font-72{
    font-size: 43.2px;
  }
  .font-73{
    font-size: 43.8px;
  }
  .font-74{
    font-size: 44.4px;
  }
  .font-75{
    font-size: 45px;
  }
  .font-76{
    font-size: 45.6px;
  }
  .font-77{
    font-size: 46.2px;
  }
  .font-78{
    font-size: 46.8px;
  }
  .font-79{
    font-size: 47.4px;
  }
  .font-80{
    font-size: 48px;
  }
  .font-81{
    font-size: 48.6px;
  }
}

@media(min-width: 992px) {
  .col-md-2-4{
    width: 20%;
  }
}
