.post-preview{
  height: 100%;
}

.blog-preview-wrapper{
  display: flex;
  flex-wrap: wrap;
}

.blog-preview-wrapper > div {
  flex-basis: 33%;
  margin-bottom: 20px;
}

.blog-preview-wrapper > div.col-md-12 {
  flex-basis: 100%;
  margin-bottom: 20px;
}

@media(max-width: 991px) {
  .blog-preview-wrapper > div {
    flex-basis: 50%;
  }
}

@media(max-width: 665px) {
  .blog-preview-wrapper > div {
    flex-basis: 100%;
  }
}
