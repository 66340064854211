.FWTable.table{
  margin-bottom: 0px;
}

.FWTable.table:before{
  content: '';
}

.FWTable.table > thead > tr > th{
  font-size: 16px;
}

.FWTable.table > tbody > tr > td{
  vertical-align: middle;
  padding: 8px;
  font-size: 15px;
}

.FWTable.table > tbody > tr > td:last-child, .FWTable.table > thead > tr > th:last-child{
  text-align: right;
}

.FWTable.table > tbody > tr > td .fa {
  font-size: 20px;
}

.table.table-condensed tr td:first-child{
  font-weight: bold;

}