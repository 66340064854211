#searchWrapper{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.8);
  z-index: 100000;

  #searchBox{
    position: relative;
    margin-top: 50vh;
    transform: translateY(-50%);
    color: white;
    text-align: center;

    h1{
      margin-bottom: 40px;
      word-break: break-word;
    }

    input, button{
      color: #333;
      font-size: 24px;
      border-radius: 100px;
    }

    #textField{
      width: 500px;
      padding: 10px;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      max-width: 90%;
    }

    #submitButton{
      margin-left: 10px;
      background: none;
      color: white;
      i {
        padding: 15px 12px;
      }
    }

    #cancelButton{
      margin-top: 30px;
      color: #aaa;
      font-weight: bold;
      border-color: #aaa;
    }
  }
}