.photo-gallery{

  .photo-gallery-photo{
    cursor: pointer;
  }
}

.pswp{
  display: none;
}

.pswp--open{
  display: block;
}
