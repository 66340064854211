@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin filter($filter){
  -webkit-filter: $filter;
  -moz-filter: $filter;
  -o-filter: $filter;
  -ms-filter: $filter;
  filter: $filter;
}

.finalweb-loader-wrapper{
  padding: 10px;
  width: 100px;
  height: 100px;
  margin: auto;
}
.finalweb-loader{
  width: 100%;
  height: 100%;
  position: relative;
}

.finalweb-loader-wrapper .loading-text{
  font-size: 20px;
  color: rgba(0,0,0,.6)
}

.util-loading-text {
  font-size: 24px;
  color: white;
  margin-top: calc(50vh + 55px);
  position: absolute;
  width: 100vw;
  text-align: center;
  padding-top: 0;
  font-family: 'Roboto', sans-serif;
  @include box-shadow(13px -7px 10px -9px rgba(0, 0, 0, 0.75));
  letter-spacing: 1px;
  font-weight: 100;
}

.full-screen .finalweb-loader-wrapper .loading-text{
  color: #fff;
}

.finalweb-loader .block{
  width: 20%;
  height: 20%;
  background: #fff;
  position: absolute;
  animation-name: bulge;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  background: linear-gradient(135deg, #ffffff, #000000, #ffffff);
  background-size: 300px 300px;
  @include box-shadow(1px 1px 8px 0px rgba(255,255,255,.05));
}

.finalweb-loader .block-1{
  background-image: linear-gradient(135deg, #ffffff, #000000, #ffffff);
  background-position: -10px -10px;
  top: 10%;
  left: 5%;
  animation-delay: 200ms;
}

.finalweb-loader .block-2{
  top: 10%;
  left: 27%;
  background-position: -20px -20px;
  animation-delay: 300ms;
}

.finalweb-loader .block-3{
  top: 10%;
  left: 49%;
  background-position: -30px -30px;
  animation-delay: 400ms;
}

.finalweb-loader .block-4{
  top: 32%;
  left: 5%;
  background-position: -20px -20px;
  animation-delay: 300ms;
}

.finalweb-loader .block-5{
  top: 32%;
  left: 27%;
  background-position: -30px -30px;
  animation-delay: 400ms;
}

.finalweb-loader .block-6{
  top: 54%;
  left: 5%;
  background-position: -30px -30px;
  animation-delay: 400ms;
}

.finalweb-loader .block-7{
  top: 16%;
  left: 75%;
  background-position: -70px -70px;
  animation-delay: 500ms;
}

.finalweb-loader .block-8{
  top: 38%;
  left: 53%;
  background-position: -70px -70px;
  animation-delay: 500ms;
}

.finalweb-loader .block-9{
  top: 38%;
  left: 75%;
  background-position: -90px -90px;
  animation-delay: 600ms;
}

.finalweb-loader .block-10{
  top: 60%;
  left: 53%;
  background-position: -90px -90px;
  animation-delay: 600ms;
}

.finalweb-loader .block-11{
  top: 60%;
  left: 31%;
  background-position: -70px -70px;
  animation-delay: 500ms;
}

@keyframes bulge {
  0% {
    transform: scale(1.02);
  }

  20% {
    transform: scale(.7);
  }

  40% {
    transform: scale(1.02);

  }

  100%{
    transform: scale(1.02);

  }
}

@media(max-width: 400px) {
  .video-section .finalweb-loader-wrapper{
    zoom: 75%;
  }
}
