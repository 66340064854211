
.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
}

@media(min-width: 768px) {
  .dropdown-menu li:hover .sub-menu {
    visibility: visible;
  }
}

.dropdown.disableHover ul:hover .sub-menu{
  display:none;
}

@media(min-width: 768px) {
  .dropdown-toggle:hover .dropdown-menu {
    display: block;
  }
}

.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
  margin-top: 0;
}

.navbar .sub-menu:before {
  border-bottom: 7px solid transparent;
  border-left: none;
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 7px solid transparent;
  left: -7px;
  top: 10px;
}
.navbar .sub-menu:after {
  border-top: 6px solid transparent;
  border-left: none;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
  left: 10px;
  top: 11px;
  left: -6px;
}

#menuSearchValue, #menuSearchButton {
  background: transparent;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  transform: translateY(-5px);
}

#menuSearchValue {
  border: none;
  border-bottom: 3px solid;
  margin-left: 5px;
}

#menuSearchButton {
  border: none;
  margin-left: -22px;
}
