#printDirectory{
  h5{
    font-size: 10pt;
  }
  h4{
    font-size: 11pt;
  }
  h3{
    font-size: 14pt;
  }
  h2{
    font-size: 16pt;
  }
  h1{
    text-align: center;
    font-size: 32pt;
    small{
      color: #999;
      font-size: .7em;
      font-weight: bold;
    }
  }
  #families{
    padding: 0 .5cm;
    border-collapse: collapse;
  }
  .membershipFamily{
    border: 1px solid black;
  }
  .membershipFamily, .familyMember{
    padding: 10px;
    .familyPictureContainer, .personPictureContainer {
      height:150px;
      width: 300px;
      text-align: center;
      img.familyPhoto, img.personPhoto {
        max-height: 100%;
        max-width: 100%;
      }
      .familyIcon, .personIcon {
        font-size: 50pt;
        padding-top: 50px;
      }
      .familyPhoto, .familyIcon, .personPhoto, .personIcon{
        display: inline-block;
      }
    }
    .street, .cityStateZip, .homePhone, .email, .workPhone, .pagerNumber, .cellPhone{
      padding-left: 3px;
      i{
        margin-right: 5px;
      }
    }
    .street2{
      padding-left: 10px;
    }
    .familyMember{

    }
  }

  .familyMember{
    page-break-inside: avoid;
    border-bottom: 1px solid #333;
  }

  .familyMember:last-child{
    border-bottom: none;
  }

  .noBreak{
    page-break-inside: avoid;
  }
}