.FWMediaSection.standard-layout {

  .file-icon{
    font-size: 100px;
    text-align: center;
    line-height: 200px;
    height: 200px
  }

  .files {
    display: flex;
    flex-wrap: wrap;
  }

  .media-file {
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    width: calc(100% * (1 / 3) - 20px);
    margin-bottom: 15px;
    background: rgba(125, 125, 125, 0.25);
    cursor: pointer;
    position: relative;

    .media-image {
      height: 200px;
      display: block;
      text-align: center;
      border-radius: 5px;
      background-size: cover;
      background-position: center;

      .media-image-overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 26, 255, 0.11);
      }
    }
    h3 {
      margin-top: 5px;
      font-size: 1.8rem;
      font-weight: bold;
    }

    a{
      display: block;
    }

    h4 small{
      white-space: nowrap;
    }
  }

  .media-file.active{
    background: #a7a7a7;
  }

  .media-file.active:hover{
    background: #a7a7a7;
  }

  .media-list-file {
    padding: 2px;
    border-radius: 5px;
    overflow: auto;
    background: rgba(125, 125, 125, 0.25);
    cursor: pointer;
    text-align: left;
    margin-bottom: 10px;

    h3, h4 {
      margin: 0;
      padding: 0;
      display: inline-block;
      margin-right: 6px;
    }

    h4{
      //line-height: 36px;
    }

    h3{
      //line-height: 30px;
    }

    small{
      margin-left: 7px;
    }
  }

  @media(max-width: 992px) {
    .media-file {
      width: calc(100% * (1 / 2) - 20px);
    }
  }

  @media(max-width: 480px) {
    .media-file {
      width: calc(100% * (1 / 1) - 20px);
    }
  }

  .dropdown {
    display: inline-block;
    margin-right: 5px;

    a {
      cursor: pointer;
    }
  }

  .media-file:hover {
    background: rgba(125, 125, 125, 0.35);
  }

  /*@element ".files" and (max-width: 300px) {
    .media-file {
      background: greenyellow;
      border-color: limegreen;
    }
  }*/

}

.FWMediaSection.standard-layout.single-file {
  .media-file{
    width: 100%;
  }
}

.FWMediaSection {
  .dropdown{
    .dropdown-menu{
      max-height: 300px;
      overflow-x: hidden;
    }
  }
  .media-file{
    .file-price{
      text-align: right;
      width: 100%;
      display: block;
      font-size: 1rem;
      top: -5px;
      position: relative;
    }
  }
}
