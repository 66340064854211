.searchTableScroll{
    overflow: auto;
    background:
            /* Shadow covers */
            linear-gradient(white 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,

              /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.7), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.7), rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

  .table-striped > thead > tr{
    background-color: transparent;
  }

  .table-striped > tbody > tr:nth-of-type(odd){
    background-color: rgba(210,210,210,0.3);
  }
}
